// any app specific constants that you don't want to keep in config can go here.
export const SYSTEM_ERROR = "System error. Please try again later!";
export const CTA_YES = "Yes";
export const CTA_NO = "No";
export const CTA_CANCEL = "Cancel";

//local
//export const ES_AdminURL = "https://localhost:44350/";

//prod
export const ES_AdminURL = "https://esadmin.fourmatics.ca/";
