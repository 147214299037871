import ESAPI from "../../http-common";

const getAll = data => {
    return ESAPI.get(`Event/GetAllByChapterId?ChapterRecId=${data.ChapterRecId}&UserRecId=${data.UserRecId}`);
};

const create = data => {
    return ESAPI.post("Event/CreateEvent", data);
};

const update = (data) => {
    return ESAPI.post(`Event/UpdateEvent`, data);
};

const remove = (data) => {
    return ESAPI.post(`Event/DeleteEvent`, data);
};



const getMembersReport = data => {
    return ESAPI.get(`Report/GetMembershipListReport?ChapterRecId=${data.ChapterRecId}&UserRecId=${data.UserRecId}`)
}


const EventService = {
    getAll,
    create,
    update,
    remove,
    getMembersReport

};

export default EventService;