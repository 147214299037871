
import {
  configureStore,
  combineReducers,
} from '@reduxjs/toolkit';

import userReducer from 'redux/slices/usersSlice'
import memberShipReducer from 'redux/slices/memebershipSlice'
import eventReducer from 'redux/slices/eventSlice'
import eventRegistrationReducer from 'redux/slices/eventRegistrationSlice'


const combinedReducer = combineReducers({
  users: userReducer,
  memeberShip: memberShipReducer,
  events: eventReducer,
  eventRegisteredMember: eventRegistrationReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'users/Logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};



const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})

export default store;



