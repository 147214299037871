
import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Switch, Redirect, Link, useHistory } from "react-router-dom";

import AuthLayout from "layouts/AuthLayout.js";
import AdminLayout from "layouts/AdminLayout.js";
import PublicRoute from "PublicPrivateRoutes/PublicRoute";
import PrivateRoute from "PublicPrivateRoutes/PrivateRoute";
import store from "redux/store";
import { Provider } from 'react-redux'



import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

function App() {

  return (<BrowserRouter>
    <Switch>
      <PublicRoute component={AuthLayout} path="/" exact />
      <PrivateRoute component={AdminLayout} path="/admin/dashboard" />
      <PrivateRoute component={AdminLayout} path="/admin/membership" />
      <PrivateRoute component={AdminLayout} path="/admin/event" />
      <PrivateRoute component={AdminLayout} path="/admin/registememberlist" />
      <PrivateRoute component={AdminLayout} path="/admin/verifyregisteredmembers" />


    </Switch>
  </BrowserRouter>)
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
  <App />
</Provider>);
