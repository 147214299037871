import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function LoadingScreen() {
    return (
        <Box id="boxLoading" top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <div style={{ position: 'relative' }}>
                <CircularProgress size="5rem" color="inherit" />
            </div>
        </Box>
        // <Box position="relative" display="inline-flex"></Box >
    );
}