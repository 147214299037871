import React, { Suspense } from "react";
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from "utils/userSession";
import LoadingScreen from 'layouts/LoadingScreen'


const PublicRoute = ({ component: Component, restricted, ...rest }) => {

    console.log("Public Route run")
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Route {...rest} render={props => (
                isAuthenticated() ?
                    <Redirect to="/admin/dashboard" />
                    : <Component {...props} />
            )} /></Suspense>
    );
};

export default PublicRoute;