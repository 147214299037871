import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MemeberShipService from "redux/services/MemberService";

const initialState = {
    loading: false,
    memeberShipList: [],
    errorMessage: ""
}

export const retriveMemberShipList = createAsyncThunk(
    "memeberShipSlice/retriveMemberShipList",
    async ({ userData }, { rejectWithValue }) => {
        try {
            const res = await MemeberShipService.getAll(userData);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }

    }
);

export const createMemberShip = createAsyncThunk(
    "memeberShipSlice/createMemberShip",
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await MemeberShipService.create(data);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }
    }
);

export const updateMemberShip = createAsyncThunk(
    "memeberShipSlice/updateMemberShip",
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await MemeberShipService.update(data);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }
    }
);



export const deleteMembership = createAsyncThunk(
    "memeberShipSlice/deleteMembership",
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await MemeberShipService.remove(data);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }

    }
);

export const sendEmail = createAsyncThunk(
    "memeberShipSlice/sendEmail",
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await MemeberShipService.sendEmail(data);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }

    }
);

export const getMembersReport = createAsyncThunk(
    "memeberShipSlice/getMembersReport",
    async ({ userData }, { rejectWithValue }) => {
        try {
            const res = await MemeberShipService.getMembersReport(userData);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }

    }
);

const memebershipSlice = createSlice({
    name: "memeberShiplist",
    initialState,
    extraReducers: {
        [retriveMemberShipList.pending]: (state) => {
            state.loading = true
        },
        [retriveMemberShipList.fulfilled]: (state, { payload }) => {
            state.loading = false
            if (payload.succeed) {
                state.memeberShipList = [...payload.value]
            } else {
                state.loading = false
                state.errorMessage = payload.error;
            }

        },
        [retriveMemberShipList.rejected]: (state, { payload }) => {
            state.loading = false
            state.errorMessage = payload;
        },
        [createMemberShip.fulfilled]: (state, { payload }) => {
            return payload
        },
        [createMemberShip.rejected]: (state) => {
            state.loading = false
        },
        [updateMemberShip.fulfilled]: (state, { payload }) => {
            state.loading = false
            if (payload.succeed) {
                const index = state.memeberShipList.findIndex(x => x.id === payload.value.MemberRecId);
                state[index] = {
                    ...state[index],
                    payload,
                };
            } else {
                state.loading = false
                state.errorMessage = payload.error;
            }
        },
        [updateMemberShip.rejected]: (state, { payload }) => {
            console.log(payload)
        },
        [deleteMembership.fulfilled]: (state, { payload }) => {
            return payload;
        },
        [deleteMembership.rejected]: (state, { payload }) => {
            return payload;
        },
        [sendEmail.fulfilled]: (state, { payload }) => {
            return payload;
        },
        [getMembersReport.fulfilled]: (state, { payload }) => {
            return payload;
        },

    }
})

const { reducer } = memebershipSlice;
export default reducer;